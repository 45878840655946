import { ComponentCanDeactivate } from '../../../guards/pending-changes.guard';
import { forkJoin, Observable } from "rxjs";
import { StrengthWorkout } from "../../../models/strength-workout.model";
import { ActivatedRoute } from "@angular/router";
import { StrengthWorkoutService } from "../../../services/strength-workout.service";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import Swal from "sweetalert2/src/sweetalert2.js";
import * as moment from "moment";
import { AchievementService } from "src/app/services/achievement.service";

@Component({
  selector: "app-edit-strength-workout",
  templateUrl: "./edit-strength-workout.component.html",
  styleUrls: ["./edit-strength-workout.component.scss"],
})
export class EditStrengthWorkoutComponent implements OnInit, ComponentCanDeactivate {
  @ViewChild("form", { static: false }) entryForm?: NgForm;
  submitted = false;
  error?: string;
  public errors: any = [];
  isLoading = false;
  public entryId!: number;
  public workout?: StrengthWorkout;
  maxDate = new Date();
  workoutDate?: Date;
  exercisesPicked: any = [];
  exercisesSelected = false;
  submittedStep = [false, false, false, false];
  hasDeleted = false;


   // biceps curls, Pull ups/pull ups, sit to stand and step ups
   exercises = [{id:'bicepCurl', label:'Bicep Curls'},{id:'pullUps', label:'Pull ups'},{id:'sitToStand', label:'Sit to Stand'},{id:'stepUps', label:'Step ups'}];
   step = 0;
   exerciseData:any;

  constructor(
    private strengthWorkoutService: StrengthWorkoutService,
    private router: Router,
    private route: ActivatedRoute,
    private achievementService: AchievementService,
    private el: ElementRef
  ) {}
  
  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.entryId = params.id;
      this.isLoading = true;
      forkJoin({
        workout: this.strengthWorkoutService.fetch(this.entryId),
      }).subscribe(
        (responseData) => {
          this.isLoading = false;
          this.workout = responseData.workout;
          this.exerciseData = JSON.parse(this.workout.exercises);
          if(!this.exerciseData){
            this.exerciseData = {
              bicepCurl : {weight:null, set1:'', set2:'', set3:'', difficulty:0},
              pullUps : {weight:null, set1:'', set2:'', set3:'', difficulty:0},
              sitToStand : {weight:null, set1:'', set2:'', set3:'', difficulty:0},
              stepUps : {weight:null, set1:'', set2:'', set3:'', difficulty:0},
            };
          }
          // fill in blank data
          this.exercises.forEach((exercise) => {
            if(!this.exerciseData[exercise.id]){
              this.exerciseData[exercise.id] = {weight:null, set1:'', set2:'', set3:'', difficulty:0};
            }
            else{
              this.exercisesPicked.push(exercise);
            }
          });
          this.workoutDate = moment(this.workout.workout_date).toDate();
        },
        (error) => {
          this.errors.push(error.message);
        }
      );
    });
  }

  onSubmit() {
    this.submitted = true;

    if (!this.entryForm?.valid) {
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });
    } else {
      // create entry
      const resourceIds = [];
      this.strengthWorkoutService
        .update(
          this.entryId,
          this.entryForm?.value.workout_date,
          this.exerciseData
        )
        .subscribe(
          (responseData) => {
            Swal.fire({
              position: "top-end",
              icon: "success",
              title: "Entry updated",
              showConfirmButton: false,
              timer: 2000,
              timerProgressBar: true,
              toast: true,
            });
            this.router.navigate(["/exercise", "strength"]);
            this.entryForm?.reset();
            this.achievementService.refreshNotifications();
          },
          (error) => {
            this.error = error.message;
          }
        );
    }
  }

  
  onNext(){
    if(!this.exerciseData[this.exercisesPicked[this.step].id].weight || !this.exerciseData[this.exercisesPicked[this.step].id].set1 || !this.exerciseData[this.exercisesPicked[this.step].id].set2 || !this.exerciseData[this.exercisesPicked[this.step].id].set3 || !this.exerciseData[this.exercisesPicked[this.step].id].difficulty){
      Swal.fire({
        icon: "error",
        title: "The form is invalid",
        text: "Please check the form for errors",
        showConfirmButton: true,
        didClose: () => this.scrollToFirstInvalidControl(),
      });

    this.submittedStep[this.step] = true;
    }
    else{
    this.step++;
    }
  }
  onPrev(){
    this.step--;
  }




  onDeleteEntry() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.strengthWorkoutService.delete(this.entryId).subscribe(
          (responseData) => {

            this.hasDeleted = true;
            Swal.fire('Deleted', 'Your entry has been deleted.', 'success');
            this.router.navigate(['/exercise', 'cardio']);
          },
          (error) => {
            this.isLoading = false;
            this.errors.push(error.message);
            Swal.fire('Error!', 'There was an error.', 'error');
          }
        );
      }
    });
  }


  onPickExercise(exercise:any) {
    if (this.exercisesPicked.indexOf(exercise) !== -1) {
      this.exercisesPicked.splice(this.exercisesPicked.indexOf(exercise), 1);
    } else {
      this.exercisesPicked.push(exercise);
    }
  }
  onPickAll(){
    this.exercisesPicked = [];
    this.exercises.forEach((exercise) => {
      this.exercisesPicked.push(exercise);
    });
  }
  onConfirmExercises(){
    this.exercisesSelected = true;
  }

  public scrollToFirstInvalidControl() {
    const firstInvalidControl: HTMLElement =
      this.el.nativeElement.querySelector("form .ng-invalid");

    if (firstInvalidControl) {
      firstInvalidControl.focus();
      window.scroll({
        top: this.getTopOffset(firstInvalidControl),
        left: 0,
        behavior: "smooth",
      });
    }
  }

  public getTopOffset(controlEl: HTMLElement): number {
    const labelOffset = 150;
    return controlEl.getBoundingClientRect().top + window.scrollY - labelOffset;
  }

  @HostListener('window:beforeunload')
  canDeactivate(): Observable<any> | any {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    if (this.submitted || this.hasDeleted) {
      return true;
    }
  }
}
