<div
  [ngClass]="{
    'container-narrow': router.isActive('/register', true)
  }"
>
  <nav
    class="navbar navbar-expand-lg navbar-main"
    [ngClass]="{
      'navbar-tall': tallNav,
      'bg-dark navbar-dark': !navbarOptions?.style,
      'bg-white navbar-light': navbarOptions?.style == 'light'
    }"
    aria-label="Main top navigation"
  >
    <div class="container justify-content-center align-items-start relative-md">
      <button
        (click)="onBack()"
        class="back-link btn"
        *ngIf="
          !router.isActive('/dashboard', true) &&
          !router.isActive('/register', true) &&
          !router.isActive('/getting-started', true)
        "
      >
        <fa-icon [icon]="['fal', 'arrow-left']"></fa-icon>
        <span class="sr-only">Back</span>
      </button>

      <a class="navbar-brand mx-auto">
        <h1>
          <img
            [ngClass]="{ 'd-lg-none': home }"
            class="d-block"
            *ngIf="!pageTitle || home"
            src="/assets/img/logo-impact-white-new.svg"
            alt="Logo"
          />
          <span *ngIf="pageIcon" class="icon-wrap">
            <fa-icon [icon]="[pageIcon.variant, pageIcon.icon]"></fa-icon>
          </span>
          <span *ngIf="pageTitle" [ngClass]="{ 'd-none d-lg-block': home }">{{
            pageTitle
          }}</span>
        </h1>
      </a>

      
      <div
        class="ms-auto btn-group btn-group-navbar-end"
        dropdown
        *ngIf="!router.isActive('/register', true) && home"
      >
        <!--<button class="btn-nav-menu relative" type="button" [routerLink]="['/notifications']">
          <span *ngIf="notifications.length>0" class="position-absolute  translate-middle bg-danger border border-light rounded-circle" style="right:-8px;top:5px;padding:6px;"><span class="visually-hidden">New notifications</span></span>
          <fa-icon [icon]="['fal', 'bell']"></fa-icon>
          <span class="sr-only">Notifications</span>
        </button>-->

        <!--<button
          class="btn-nav-menu"
          id="user-dropdown-btn"
          (click)="onToggleAsideMenu()"
          type="button"
        >
          <fa-icon [icon]="['fal', 'user']"></fa-icon>
          <span class="sr-only">Menu</span>
        </button>-->
      </div>

      <div class="ms-auto btn-group btn-group-navbar-end" dropdown *ngIf="navbarOptions?.addLink">
        <button
          class="btn-nav-menu relative"
          type="button"
          [routerLink]="this.parseLink(this.route.root.firstChild?.snapshot?.data?.navbarOptions.addLink, this.route.root.firstChild?.snapshot?.params)"
        >
          <fa-icon [icon]="['fal', 'circle-plus']"></fa-icon>
          <span class="sr-only">Add Entry</span>
        </button>
      </div>
      <div class="ms-auto btn-group" dropdown *ngIf="navbarOptions?.sideAction">
        <button
          class="btn-nav-menu"
          type="button"
          (click)="onSideAction($event)"
        >
          <fa-icon [icon]="['fal', navbarOptions?.sideActionIcon]"></fa-icon>
          <span class="sr-only">{{navbarOptions?.sideAction}}</span>
        </button>
      </div>
    </div>
  </nav>
</div>
<app-aside-menu
  [hidden]="!showAside"
  (closeMenu)="onCloseMenu($event)"
></app-aside-menu>
