import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './home/home.component';
import {
  NgcCookieConsentConfig,
  NgcCookieConsentModule,
} from 'ngx-cookieconsent';
import { LoginComponent } from './auth/login/login.component';

import { interceptorProviders } from './interceptors';
import {
  HttpClient,
  HttpClientModule,
  HttpClientXsrfModule,
} from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
export function provideSwal() {
  return import('sweetalert2/src/sweetalert2.js'); // instead of import('sweetalert2')
}

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

import { NgChartsModule } from 'ng2-charts';

// import { config } from '@fortawesome/fontawesome-svg-core';
// config.autoAddCss = false;
import {
  FaIconLibrary,
  FontAwesomeModule,
} from '@fortawesome/angular-fontawesome';
import {
  faChevronRight as falChevronRight,
  faChevronLeft as falChevronLeft,
  faArrowAltRight as falArrowAltRight,
  faLockAlt as falLockAlt,
  faLockKeyholeOpen as falLockKeyholeOpen,
  faStar as falStar,
  faStopwatch as falStopwatch,
  faHeadSideBrain as falHeadSideBrain,
  faWeight as falWeight,
  faSmokingBan as falSmokingBan,
  faPaperPlane as falPaperPlane,
  faUser as falUser,
  faKey as falKey,
  faTasks as falTasks,
  faChartLine as falChartLine,
  faBookReader as falBookReader,
  faUserMdChat as falUserMdChat,
  faEllipsisH as falEllipsisH,
  faEllipsisV as falEllipsisV,
  faBullseyePointer as falBullseyePointer,
  faVideo as falVideo,
  faFileAlt as falFileAlt,
  faMousePointer as falMousePointer,
  faAngleDown as falAngleDown,
  faAngleUp as falAngleUp,
  faHashtag as falHashtag,
  faDumbbell as falDumbbell,
  faThermometerHalf as falThermometerHalf,
  faThermometerEmpty as falThermometerEmpty,
  faThermometerFull as falThermometerFull,
  faHeadSideCough as falHeadSideCough,
  faCouch as falCouch,
  faLungs as falLungs,
  faSnooze as falSnooze,
  faCommentAltLines as falCommentsAltLines,
  faHeadset as falHeadset,
  faTrash as falTrash,
  faSadTear as falSadTear,
  faBoxTissue as falBoxTissue,
  faHeart as falHeart,
  faSmile as falSmile,
  faBolt as falBolt,
  faComments as falComments,
  faCircle as falCircle,
  faSquare as falSquare,
  faInfoCircle as falInfoCircle,
  faUsers as falUsers,
  faHospitalAlt as falHospitalAlt,
  faBalanceScale as falBalanaceScale,
  faFingerprint as falFingerprint,
  faMobile as falMobile,
  faSpinner as falSpinner,
  faArrowRight as falArrowRight,
  faArrowLeft as falArrowLeft,
  faHouseBlank as falHouseBlank,
  faHeartPulse as falHeartPulse,
  faBarsProgress as falBarsProgress,
  faBars as falBars,
  faBooks as falBooks,
  faBell as falBell,
  faCirclePlus as falCirclePlus,
  faCircleMinus as falCircleMinus,
  faMedal as falMedal,
  faMessage as falMessage,
  faSignOut as falSignOut,
  faCalendarAlt as falCalendarAlt,
  faGauge as falGauge,
  faWeightScale as falWeightScale,
  faLock as falLock,
  faListCheck as falListCheck,

  // faArrowCircleRight as falArrowCircleRight
  // faRedoAlt as falRedoAlt
  // faBars as falBars
} from '@fortawesome/pro-light-svg-icons';
import {
  faCheck as farCheck,
  faTimes as farTimes,
  faBars as farBars,
  faRedoAlt as farRedoAlt,
  faArrowCircleRight as farArrowCircleRight,
  faArrowCircleLeft as farArrowCircleLeft,
  faChevronLeft as farChevronLeft,
  faChevronDown as farChevronDown,
  faCircle as farCircle,
  faArrowAltRight as farArrowAltRight,
  faArrowAltLeft as farArrowAltLeft,
  faArrowsAltV as farArrowsAltV,
  faCalendarAlt as farCalendarAlt,
  faFrownOpen as farFrownOpen,
  faUtensils as farUtensils,
  faHeadSideBrain as farHeadSideBrain,
  faMeh as farMeh,
  faWalking as farWalking,
  faFileCertificate as farFileCertificate,
  faFileDownload as farFileDownload,
  faSearch as farSearch,
  faVideo as farVideo,
  faFileAlt as farFileAlt,
  faMousePointer as farMousePointer,
  faCameraAlt as farCameraAl,
  faMicrophoneStand as farMicrophoneStand,
  faThermometerHalf as farThermometerHalf,
  faBullseyePointer as farBullseyePointer,
  faInfoCircle as farInfoCircle,
  faUsers as farUsers,
  faHospitalAlt as farHospitalAlt,
  faBalanceScale as farBalanaceScale,
  faFingerprint as farFingerprint,
  faPenAlt as farPenAlt,
  faMobile as farMobile,
  faHeadSideCough as farHeadSideCough,
  faCouch as farCouch,
  faLungs as farLungs,
  faSnooze as farSnooze,
  faPaperPlaneTop as farPaperPlaneTop,
  faTint as farTint,
  faFaceTired as farFaceTired,
  faFaceExhaling as farFaceExhaling,
  faScalpel as farScalpel,
  faBootHeeled as farBootHeeled,
  faArrowsH as farArrowsH,
  faStars as farStars,
  faStar as farStar,
  faKitchenSet as farKitchenSet,
  faPlus as farPlus,
  faMinus as farMinus,
  faPersonRunning as farPersonRunning,
  faCommentSmile as farCommentSmile,
  faCommentsQuestion as farCommentsQuestion,
  faGraduationCap as farGraduationCap,
  faSignOut as farSignOut,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faCheck as fasCheck,
  faArrowUp as fasArrowUp,
  faArrowDown as fasArrowDown,
  faCircle as fasCircle,
  faCircleArrowRight as fasCircleArrowRight,
  faCircleDot as fasCircleDot,
  faCheckCircle as fasCheckCircle,
  faArrowAltRight as fasArrowAltRight,
  faArrowAltLeft as fasArrowAltLeft,
  faCheckSquare as fasCheckSquare,
  faHouseBlank as fasHouseBlank,
  faHeartPulse as fasHeartPulse,
  faBarsProgress as fasBarsProgress,
  faBars as fasBars,
  faBooks as fasBooks,
  faHeart as fasHeart,
  faMeh as fasMeh,
  faTired as fasTired,
  faFrown as fasFrown,
  faSmile as fasSmile,
  faGrinStars as fasGrinStars,
  faCalendarAlt as fasCalendarAlt,
  faStar as fasStar,
  faAward as fasAward,
  faCircleExclamation as fasCircleExclamation,
  faClock as fasClock,
  faCaretDown as fasCaretDown,
  faTriangleExclamation as fasTriangleExclamation,
  faTimes as fasTimes
} from '@fortawesome/pro-solid-svg-icons';


import { ForgottenPasswordComponent } from './auth/forgotten-password/forgotten-password.component';
import { LoadingSpinnerComponent } from './shared/loading-spinner/loading-spinner.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { RegisterComponent } from './auth/register/register.component';
import { NavbarComponent } from './navbar/navbar.component';
import { AsideMenuComponent } from './aside-menu/aside-menu.component';
import { AddToHomescreenComponent } from './add-to-homescreen/add-to-homescreen.component';
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MoreComponent } from './more/more.component';
import { HeaderBarComponent } from './header-bar/header-bar.component';

// bootstrap
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UpdateDetailsComponent } from './auth/update-details/update-details.component';
import { ChangePasswordComponent } from './auth/change-password/change-password.component';
import { DndModule } from 'ngx-drag-drop';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

import { CheckPasswordDirective } from './directives/check-password.directive';
import { UploadAvatarComponent } from './auth/upload-avatar/upload-avatar.component';
import { OfflineComponent } from './offline/offline.component';
import { MfaComponent } from './auth/mfa/mfa.component';
import { DatePipe } from '@angular/common';
import { ExerciseIndexComponent } from './exercise/exercise-index/exercise-index.component';
import { CardioIndexComponent } from './exercise/cardio/cardio-index/cardio-index.component';
import { AddCardioWorkoutComponent } from './exercise/cardio/add-cardio-workout/add-cardio-workout.component';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { EditCardioWorkoutComponent } from './exercise/cardio/edit-cardio-workout/edit-cardio-workout.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { AchievementNotificationComponent } from './achievements/achievement-notification/achievement-notification.component';
import { AchievementBadgeComponent } from './achievements/achievement-badge/achievement-badge.component';
import { ResourcesComponent } from './resources/resources.component';
import { GetThumbnailPipe } from './pipes/get-thumbnail.pipe';

// chat
import { ExpertComponent } from './chat/expert/expert.component';
import { ChatComponent } from './chat/chat.component';
import { SupportComponent } from './chat/support/support.component';
import { ForumComponent } from './chat/forum/forum.component';
import { ForumCategoryComponent } from './chat/forum/forum-category/forum-category.component';
import { ForumTopicComponent } from './chat/forum/forum-topic/forum-topic.component';
import { ForumTopicAddComponent } from './chat/forum/forum-topic-add/forum-topic-add.component';

// goals
import { GoalsComponent } from './goals/goals.component';
import { GoalBuilderComponent } from './goals/goal-builder/goal-builder.component';
import { GoalViewComponent } from './goals/goal-view/goal-view.component';
import { GoalAddComponent } from './goals/goal-add/goal-add.component';
import { GoalUpdateComponent } from './goals/goal-update/goal-update.component';
import { GoalsAboutComponent } from './goals/goals-about/goals-about.component';

import { TimeagoPipe } from './pipes/timeago.pipe';
import { StrengthIndexComponent } from './exercise/strength/strength-index/strength-index.component';
import { DifficultyIconComponent } from './shared/difficulty-icon/difficulty-icon.component';
import { AddStrengthWorkoutComponent } from './exercise/strength/add-strength-workout/add-strength-workout.component';
import { EditStrengthWorkoutComponent } from './exercise/strength/edit-strength-workout/edit-strength-workout.component';
import { FormatStrengthExercisesComponent } from './exercise/strength/format-strength-exercises/format-strength-exercises.component';
import { ResourceIndexComponent } from './resources/resource-index/resource-index.component';
import { ResourcePageComponent } from './resources/resource-page/resource-page.component';
import { TodoComponent } from './todo/todo.component';
import { GettingStartedComponent } from './getting-started/getting-started.component';
import { WelcomeVideoComponent } from './welcome-video/welcome-video.component';
import { CatComponent } from './questionnaires/cat/cat.component';
import { Eq5dComponent } from './questionnaires/eq5d/eq5d.component';
import { SymptomBgPipe } from './pipes/symptom-bg.pipe';
import { ActionPlanSetupComponent } from './action-plan/action-plan-setup/action-plan-setup.component';
import { ActionPlanIndexComponent } from './action-plan/action-plan-index/action-plan-index.component';
import { ExerciseAboutComponent } from './exercise/exercise-about/exercise-about.component';
import { ExerciseSafetyQuizComponent } from './exercise/exercise-safety-quiz/exercise-safety-quiz.component';
import { HealthStatusIndexComponent } from './trackers/health-status/health-status-index/health-status-index.component';
import { HealthStatusUpdateComponent } from './trackers/health-status/health-status-update/health-status-update.component';
import { TermsContentComponent } from './legal/terms-content/terms-content.component';
import { HealthStatusViewComponent } from './trackers/health-status/health-status-view/health-status-view.component';
import { TrackersIndexComponent } from './trackers/trackers-index/trackers-index.component';
import { HealthStatusFeedbackComponent } from './trackers/health-status/health-status-feedback/health-status-feedback.component';
import { HealthStatusSymptomComponent } from './trackers/health-status/health-status-symptom/health-status-symptom.component';
import { AchievementsIndexComponent } from './achievements/achievements-index/achievements-index.component';
import { MorePageComponent } from './more/more-page/more-page.component';
import { PrivacyContentComponent } from './legal/privacy-content/privacy-content.component';
import { ContactComponent } from './contact/contact.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { BitesizeSessionComponent } from './bitesize/bitesize-session/bitesize-session.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { BitesizeIndexComponent } from './bitesize/bitesize-index/bitesize-index.component';
import { ReadingListComponent } from './reading-list/reading-list.component';
import { ProgrammeIndexComponent } from './programme/programme-index/programme-index.component';
import { ProgrammeProgressComponent } from './programme/programme-progress/programme-progress.component';
import { WeightIndexComponent } from './trackers/weight/weight-index/weight-index.component';
import { WeightTargetComponent } from './trackers/weight/weight-target/weight-target.component';
import { WeightUpdateComponent } from './trackers/weight/weight-update/weight-update.component';
import { FearAnxietySymptomCheckerComponent } from './resources/resource-templates/fear-anxiety-symptom-checker/fear-anxiety-symptom-checker.component';
import { QuizComponent } from './resources/resource-templates/quiz/quiz.component';
import { MustSelfScreenComponent } from './resources/resource-templates/must-self-screen/must-self-screen.component';
import { MemoryThinkingCheckupComponent } from './resources/resource-templates/memory-thinking-checkup/memory-thinking-checkup.component';
import { SleepScoreComponent } from './resources/resource-templates/sleep-score/sleep-score.component';
import { PrioritisingToolComponent } from './resources/resource-templates/prioritising-tool/prioritising-tool.component';
import { HealthStatusSetupComponent } from './trackers/health-status/health-status-setup/health-status-setup.component';
import { SymptomTextPipe } from './pipes/symptom-text.pipe';
import { FeedbackComponent } from './feedback/feedback.component';
import { GoalsManagingComponent } from './goals/goals-managing/goals-managing.component';
import { RiskFactorsComponent } from './resources/resource-templates/risk-factors/risk-factors.component';
import { StrengthIconComponent } from './exercise/strength/strength-icon/strength-icon.component';
import { FormatStrengthExercisesDetailedComponent } from './exercise/strength/format-strength-exercises-detailed/format-strength-exercises-detailed.component';
import { DifficultyCircleComponent } from './shared/difficulty-circle/difficulty-circle.component';
import { ViewStrengthWorkoutComponent } from './exercise/strength/view-strength-workout/view-strength-workout.component';
import { SearchComponent } from './search/search.component';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SymptomIconComponent } from './trackers/health-status/symptom-icon/symptom-icon.component';
import { HealthStatusBaselineIndicatorComponent } from './trackers/health-status/health-status-baseline-indicator/health-status-baseline-indicator.component';
import { DashboardMastheadCardioComponent } from './dashboard/dashboard-masthead-cardio/dashboard-masthead-cardio.component';
import { DashboardMastheadStrengthComponent } from './dashboard/dashboard-masthead-strength/dashboard-masthead-strength.component';
import { DashboardMastheadHealthComponent } from './dashboard/dashboard-masthead-health/dashboard-masthead-health.component';
import { DashboardMastheadBitesizeComponent } from './dashboard/dashboard-masthead-bitesize/dashboard-masthead-bitesize.component';
import { DashboardMastheadGoalsComponent } from './dashboard/dashboard-masthead-goals/dashboard-masthead-goals.component';

import { SwiperModule } from 'swiper/angular';

const cookieConfig: NgcCookieConsentConfig = {
  cookie: {
    domain: environment.cookieDomain,
  },
  palette: {
    popup: {
      background: '#3C5A64',
    },
    button: {
      background: '#fff',
    },
  },
  position: 'top-right',
  theme: 'classic',
  type: 'opt-in',
  content: {
    message: 'This website uses cookies for analytics. ',
    dismiss: 'Go away',
    deny: 'No thanks',
    allow: "That's fine",
    link: 'Learn more',
    href: 'https://cookiesandyou.com',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    HomeComponent,
    LoginComponent,
    ForgottenPasswordComponent,
    LoadingSpinnerComponent,
    ResetPasswordComponent,
    RegisterComponent,
    NavbarComponent,
    AsideMenuComponent,
    AddToHomescreenComponent,
    FooterNavComponent,
    FooterComponent,
    SidebarComponent,
    MoreComponent,
    HeaderBarComponent,
    DashboardComponent,
    UpdateDetailsComponent,
    ChangePasswordComponent,
    CheckPasswordDirective,
    UploadAvatarComponent,
    OfflineComponent,
    SafeHtmlPipe,
    MfaComponent,
    ExerciseIndexComponent,
    CardioIndexComponent,
    AddCardioWorkoutComponent,
    EditCardioWorkoutComponent,
    CustomDatePipe,
    AchievementNotificationComponent,
    AchievementBadgeComponent,
    ResourcesComponent,
    GetThumbnailPipe,
    ExpertComponent,
    ChatComponent,
    SupportComponent,
    ForumComponent,
    ForumCategoryComponent,
    ForumTopicComponent,
    ForumTopicAddComponent,
    TimeagoPipe,
    GoalsComponent,
    GoalBuilderComponent,
    GoalViewComponent,
    GoalAddComponent,
    GoalUpdateComponent,
    GoalsAboutComponent,
    StrengthIndexComponent,
    AddStrengthWorkoutComponent,
    EditStrengthWorkoutComponent,
    DifficultyIconComponent,
    FormatStrengthExercisesComponent,
    ResourceIndexComponent,
    ResourcePageComponent,
    TodoComponent,
    GettingStartedComponent,
    WelcomeVideoComponent,
    CatComponent,
    Eq5dComponent,
    SymptomBgPipe,
    ActionPlanSetupComponent,
    ActionPlanIndexComponent,
    ExerciseAboutComponent,
    ExerciseSafetyQuizComponent,
    HealthStatusIndexComponent,
    HealthStatusUpdateComponent,
    TermsContentComponent,
    HealthStatusViewComponent,
    TrackersIndexComponent,
    HealthStatusFeedbackComponent,
    HealthStatusSymptomComponent,
    AchievementsIndexComponent,
    MorePageComponent,
    PrivacyContentComponent,
    ContactComponent,
    NotificationsComponent,
    BitesizeSessionComponent,
    BitesizeIndexComponent,
    ReadingListComponent,
    ProgrammeIndexComponent,
    ProgrammeProgressComponent,
    WeightIndexComponent,
    WeightTargetComponent,
    WeightUpdateComponent,
    FearAnxietySymptomCheckerComponent,
    QuizComponent,
    MustSelfScreenComponent,
    MemoryThinkingCheckupComponent,
    SleepScoreComponent,
    PrioritisingToolComponent,
    HealthStatusSetupComponent,
    SymptomTextPipe,
    FeedbackComponent,
    GoalsManagingComponent,
    RiskFactorsComponent,
    StrengthIconComponent,
    FormatStrengthExercisesDetailedComponent,
    DifficultyCircleComponent,
    ViewStrengthWorkoutComponent,
    SearchComponent,
    TermsComponent,
    PrivacyComponent,
    SymptomIconComponent,
    HealthStatusBaselineIndicatorComponent,
    DashboardMastheadCardioComponent,
    DashboardMastheadStrengthComponent,
    DashboardMastheadHealthComponent,
    DashboardMastheadBitesizeComponent,
    DashboardMastheadGoalsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    // CustomFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    NgcCookieConsentModule.forRoot(cookieConfig),
    FontAwesomeModule,
    SweetAlert2Module.forRoot({
      provideSwal,
    }),
    BsDatepickerModule.forRoot(),
    TabsModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    DndModule,
    NgxSliderModule,
    NgChartsModule,
    PopoverModule.forRoot(),
    PdfViewerModule,
    SwiperModule
  ],
  providers: [interceptorProviders, DatePipe, PendingChangesGuard],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private library: FaIconLibrary) {
    library.addIcons(
      falChevronRight,
      falChevronLeft,
      falArrowAltRight,
      farCheck,
      farTimes,
      falLockAlt,
      falStar,
      falStopwatch,
      falHeadSideBrain,
      falWeight,
      falSmokingBan,
      falPaperPlane,
      falUser,
      falKey,
      falTasks,
      falChartLine,
      falBookReader,
      falUserMdChat,
      falEllipsisH,
      falBullseyePointer,
      farBars,
      falVideo,
      falFileAlt,
      falMousePointer,
      farRedoAlt,
      farArrowCircleRight,
      farArrowCircleLeft,
      farChevronLeft,
      farCircle,
      farArrowAltRight,
      fasArrowAltRight,
      fasCheck,
      farArrowsAltV,
      falAngleDown,
      falAngleUp,
      farCalendarAlt,
      falHashtag,
      falDumbbell,
      falThermometerHalf,
      falThermometerEmpty,
      falThermometerFull,
      falHeadSideCough,
      falCouch,
      falLungs,
      falSnooze,
      farFrownOpen,
      farMeh,
      farUtensils,
      farHeadSideBrain,
      falCommentsAltLines,
      falHeadset,
      falTrash,
      fasArrowUp,
      fasArrowDown,
      farWalking,
      farFileCertificate,
      farFileDownload,
      farChevronDown,
      falSadTear,
      falListCheck,
      fasCircle,
      fasCircleDot,
      falBoxTissue,
      falHeart,
      falSmile,
      falBolt,
      falComments,
      fasCheckCircle,
      falCircle,
      fasCheckSquare,
      falSquare,
      farSearch,
      farArrowAltLeft,
      farVideo,
      farFileAlt,
      farMousePointer,
      farCameraAl,
      farMicrophoneStand,
      farThermometerHalf,
      farBullseyePointer,
      farInfoCircle,
      farUsers,
      farHospitalAlt,
      farBalanaceScale,
      farFingerprint,
      falInfoCircle,
      falUsers,
      falHospitalAlt,
      falBalanaceScale,
      falFingerprint,
      farPenAlt,
      falMobile,
      farMobile,
      farHeadSideCough,
      farCouch,
      farLungs,
      farSnooze,
      farPersonRunning,
      farCommentSmile,
      farCommentsQuestion,
      farGraduationCap,
      farSignOut,
      falSpinner,
      falArrowRight,
      falArrowLeft,
      falHouseBlank,
      falHeartPulse,
      falBarsProgress,
      falBars,
      falBooks,
      falBell,
      fasHouseBlank,
      fasHeartPulse,
      fasBarsProgress,
      fasBars,
      fasBooks,
      falCirclePlus,
      falCircleMinus,
      fasHeart,
      fasMeh,
      fasTired,
      fasFrown,
      fasCircleArrowRight,
      fasSmile,
      fasGrinStars,
      falEllipsisV,
      fasCalendarAlt,
      farPaperPlaneTop,
      farTint,
      farFaceTired,
      farFaceExhaling,
      farScalpel,
      farBootHeeled,
      farArrowsH,
      farStars,
      farStar,
      fasStar,
      fasArrowAltLeft,
      falMedal,
      falMessage,
      falSignOut,
      farKitchenSet,
      falBolt,
      falCalendarAlt,
      falGauge,
      falWeightScale,
      fasAward,
      farPlus,
      farMinus,
      falLock,
      falLockKeyholeOpen,
      fasCircleExclamation,
      fasClock,
      fasCaretDown,
      fasTriangleExclamation,
      fasTimes
    );
  }
}
