<div class="sidebar-bg">
</div>
<div class="sidebar" role="navigation" aria-label="Desktop side nav">
  <div class="sidebar-content" *ngIf="userData">
      <div class="logo">
        <img src="/assets/img/logo-impact-dark-new.svg" alt="logo" class="logo" />
      </div>
      <form class="search-form mt-1 mb-3" name="searchForm">
        <div class="form-group form-floating">

          <input
            type="text"
            class="form-control"
            placeholder="Search"
            name="q"
            id="q"
            #q="ngModel"
            ngModel
            (keyup.enter)="onSearch(q)"
          />
          <label for="q">Search</label>
          <button
            (click)="onSearch(q)"
            class="btn-transparent btn btn-primary-outline"
          >
            <span class="icon-wrap"
              ><fa-icon [icon]="['far', 'search']"></fa-icon
              ><span class="sr-only">Search</span></span
            >
          </button>
        </div>
      </form>

      <div class="ms-auto btn-group user-menu-group" dropdown>
        <a id="user-dropdown-btn-sv" dropdownToggle (click)="goToNotifications()" aria-controls="dropdown-user" role="button">
          <span *ngIf="notifications.length>0" class="position-absolute top-0 translate-middle p-2 bg-danger border border-light rounded-circle" style="left:36px;"><span class="visually-hidden">New notifications</span></span>
          <img src="{{avatarBase + userData.avatar}}" alt="user avatar" class="avatar rounded" width="40" height="40" *ngIf="userData?.avatar">
          <span class="avatar-icon icon-wrap" *ngIf="!userData?.avatar">
        <fa-icon [icon]="['fal', 'user']"></fa-icon>
      </span>
          <span class="user-name">{{userData.first_name + ' ' + userData.last_name}} </span>
          <span class="icon-wrap float-end"
            ><fa-icon [icon]="['far', 'chevron-down']"></fa-icon
          ></span>
          <span class="sr-only">User Menu</span>
        </a>
        <ul
          id="dropdown-user"
          *dropdownMenu
          class="dropdown-menu"
          role="menu"
          aria-labelledby="user-dropdown-btn-sv"
        >
          <li role="menuitem">
            <a class="dropdown-item" [routerLink]="['/update-details']"
              >Update Details</a
            >
          </li>
          <!--li role="menuitem">
            <a class="dropdown-item" [routerLink]="['/upload-avatar']"
              >Upload Avatar</a
            >
          </li-->
          <li role="menuitem">
            <a class="dropdown-item" [routerLink]="['/change-password']"
              >Change Password</a
            >
          </li>
          <li role="menuitem">
            <a class="dropdown-item" [routerLink]="['']" (click)="onLogout()"
              >Logout</a
            >
          </li>
        </ul>
      </div>
      <nav class="sidebar-nav">
        <ul class="nav flex-column">
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/dashboard']"
              routerLinkActive="active"
            >
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'house-blank']"></fa-icon>
              </span>
              <span class="nav-link-title">Home</span>
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/programme']"
              [ngClass]="{'active':checkUrl() == 'programme'}"
            >
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'calendar-alt']"></fa-icon>
              </span>
              <span class="nav-link-title">Programme</span>
            </a>
            <ul class="nav flex-column fale-in animate-250">
              <!--li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/programme', 'progress']"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon-wrap duo-primary-dark">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Overall Progress
                </a>
              </li-->
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/programme', 'task-list']"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon-wrap duo-primary-dark">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Weekly To-Do List
                </a>
              </li>

              <!--li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/programme', 'weekly-reports']"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon-wrap duo-primary-dark">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Weekly Reports
                </a>
              </li-->
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/achievements']"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon-wrap duo-primary-dark">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Achievements
                </a>
              </li>
              <!--li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/programme', 'about']"
                  routerLinkActive="active"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  <span class="icon-wrap duo-primary-dark">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  About the Programme
                </a>
              </li-->
            </ul>
          </li>
          <li class="nav-item">
            <a  class="nav-link" [routerLink]="['/exercise']" routerLinkActive="active">
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'heart-pulse']"></fa-icon>
              </span>
              <span class="nav-link-title">Exercise</span>
            </a>
              <ul class="nav flex-column fale-in animate-250">
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/exercise', 'cardio']" routerLinkActive="active">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    Cardio
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/exercise', 'strength']" routerLinkActive="active">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    Strength
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [routerLink]="['/exercise', 'about']" routerLinkActive="active">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    About
                  </a>
                </li>
                <!--li class="nav-item">
                  <a class="nav-link" [routerLink]="['/exercise', 'hallenges']" routerLinkActive="active">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    Challenges
                  </a>
                </li-->
                
              </ul>
          </li>
          
          
          <li class="nav-item">
            <a  class="nav-link" [routerLink]="['/trackers']" routerLinkActive="active">
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'bars-progress']"></fa-icon>
              </span>
              <span class="nav-link-title">Trackers</span>
            </a>
              <ul class="nav flex-column fale-in animate-250">
                
                <ng-container *ngFor="let symptom of userSymptomsService.symptomsArray">
                <li class="nav-item"
                *ngIf="checkSymptomForDiagnosis(symptom.id)">
                  <a class="nav-link" [routerLink]="['/trackers', 'health-status', symptom.id]" routerLinkActive="active">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    {{symptom.title}}
                  </a>
                </li>
                </ng-container>
              
                <!--<li class="nav-item">
                  <a class="nav-link" [routerLink]="['/trackers', 'weight']" routerLinkActive="active">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    Weight
                  </a>
                </li>-->
              
              </ul>
          </li>
          <li class="nav-item">
            <a  class="nav-link" [routerLink]="['/goals']" routerLinkActive="active">
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'bullseye-pointer']"></fa-icon>
              </span>
              <span class="nav-link-title">Goals</span>
            </a>
            <ul class="nav flex-column fale-in animate-250">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/goals']" routerLinkActive="active"  [routerLinkActiveOptions]="{exact: true}">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                My Goals
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/goals', 'about']" routerLinkActive="active">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  About Goal Setting
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/goals', 'managing']" routerLinkActive="active">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Managing Expectations
                </a>
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a  class="nav-link" [routerLink]="['/resources']" [ngClass]="{'active':checkUrl() == 'resources'}">
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'books']"></fa-icon>
              </span>
              <span class="nav-link-title">Resources</span>
            </a>
            <ul class="nav flex-column fale-in animate-250">
              <li class="nav-item" *ngFor="let resource of resources">
                <a class="nav-link" [routerLink]="['/','resources', resource.content_section_id]" routerLinkActive="active">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  {{resource.title}}
                </a>
              </li>
            </ul>
    
          </li>
          <li class="nav-item" *ngIf="diagnosisMode && diagnosisMode!='covid' && this.userData.primary_diagnosis!=7  && this.userData.primary_diagnosis!=8">
            <a
              class="nav-link"
              [routerLink]="['/action-plan']"
              routerLinkActive="active"
            >
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'gauge']"></fa-icon>
              </span>
              <span class="nav-link-title">Action Plan</span>
            </a>
          </li>
          <!--<li class="nav-item">
            <a  class="nav-link" [routerLink]="['/bitesize']" routerLinkActive="active">
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'bolt']"></fa-icon>
              </span>
              <span class="nav-link-title">Bitesize Sessions</span>
            </a>
            <ul class="nav flex-column fale-in animate-250" *ngIf="bitesizeSessions">
              <ng-container *ngFor="let bitesizeSession of bitesizeSessions; let index = index">
                <li class="nav-item" *ngIf="index<currentWeekNumber">
                  <a class="nav-link" [routerLink]="['/','bitesize', bitesizeSession.bitesize_session_id]" routerLinkActive="active">
                    <span class="icon-wrap">
                      <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                    </span>
                    {{bitesizeSession.title}}
                  </a>
                </li>
              </ng-container>
            </ul>
    
          </li>-->
          <li class="nav-item">
            <a  class="nav-link" [routerLink]="['/chat']" routerLinkActive="active">
              <span class="nav-icon icon-wrap">  <fa-icon [icon]="['fal', 'user-md-chat']"></fa-icon></span>
              <span class="nav-link-title">Support</span>
            </a>
            <ul class="nav flex-column fale-in animate-250">
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/chat', 'healthcare-professional']" routerLinkActive="active">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Ask a Healthcare Professional
                </a>
              </li>
              <!--li class="nav-item">
                <a class="nav-link" [routerLink]="['/chat', 'forum']" routerLinkActive="active">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Forum
                </a>
              </li-->
              <li class="nav-item">
                <a class="nav-link" [routerLink]="['/chat', 'support']" routerLinkActive="active">
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Technical Support
                </a>
              </li>
            </ul>
          </li>
          <!--
          <li class="nav-item">
            <a
              class="nav-link"
              [routerLink]="['/more']"
              [ngClass]="{'active':checkUrl() == 'more'}"
            >
              <span class="nav-icon icon-wrap">
                <fa-icon [icon]="['fal', 'bars']"></fa-icon></span>
              <span class="nav-link-title">More</span>
            </a>
            <ul class="nav flex-column fale-in animate-250">
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/more', 'app']"
                  routerLinkActive="active"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Install Web App
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/more', 'about']"
                  routerLinkActive="active"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  About
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/contact']"
                  routerLinkActive="active"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Contact
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/more', 'terms']"
                  routerLinkActive="active"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Terms and Conditions
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['/more', 'privacy']"
                  routerLinkActive="active"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'arrow-circle-right']"></fa-icon>
                  </span>
                  Privacy Policy
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  [routerLink]="['']" (click)="onLogout()"
                >
                  <span class="icon-wrap">
                    <fa-icon [icon]="['far', 'sign-out']"></fa-icon>
                  </span>
                  Sign Out
                </a>
              </li>
            </ul>
          </li>
          -->
        </ul>
      </nav>
      <div class="tandc">
        Incorporating the programmes
        <div _ngcontent-mjc-c55="" class="programme-logos">
          <img _ngcontent-mjc-c55="" src="./assets/img/logo-ayh-white.svg" alt="Activate Your Heart Logo" width="93">
          <img _ngcontent-mjc-c55="" src="./assets/img/logo-space-white.svg" alt="SPACE for COPD" width="69">
          <img _ngcontent-mjc-c55="" src="./assets/img/logo-ycr-white.svg" alt="Your COVID Recovery" width="93">
        </div>
        <a [routerLink]="['/terms']">Terms &amp; Conditions</a> | <a [routerLink]="['/privacy']">Privacy Policy</a><br />
        &copy; {{ now | date : 'yyyy'}} NHS University Hospitals of Leicester
      </div>
  </div>
</div>
