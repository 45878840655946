import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { StrengthWorkout } from 'src/app/models/strength-workout.model';
import { UserTarget } from 'src/app/models/user-target.model';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { StrengthWorkoutService } from 'src/app/services/strength-workout.service';
import { UserTargetService } from 'src/app/services/user-target.service';

@Component({
  selector: 'app-strength-index',
  templateUrl: './strength-index.component.html',
  styleUrls: ['./strength-index.component.scss'],
})
export class StrengthIndexComponent implements OnInit {
  public isLoading = false;
  public strengthWorkouts!: StrengthWorkout[];
  public daysHit = 0;
  public weeklyTarget = { days: 5, threshold: 10 };
  public errors: any = [];
  public currentWeek: Date[] = this.dateHelperService.getCurrentWeek();
  public currentWeekData!: any[];
  public today = this.dateHelperService.getToday();

  public allTargets: UserTarget[] = [];
  public allWorkouts!: StrengthWorkout[];
  public currentTarget?: UserTarget;
  public monthsExercised: any;
  public weeksExercised: any;

  public last4Weeks: Date[] = this.dateHelperService.getLast4tWeeks();
  public last4WeeksData!: any;
  public stats: any;
  public bicepCurlsWeekReport: any;
  public pullUpsWeekReport: any;
  public sitToStandWeekReport: any;
  public stepUpsWeekReport: any;
  public userData: any;
  public programmeWeeks: any;
  showFeedback = false;
  currentWeekNumber: any;

  exercises = [
    { id: 'bicepCurl', label: 'Bicep Curls' },
    { id: 'pullUps', label: 'Pull ups' },
    { id: 'sitToStand', label: 'Sit to Stand' },
    { id: 'stepUps', label: 'Step ups' },
  ];

  public monthData!: any;
  monthSelected = moment().toDate();
  currentMonth = moment().toDate();

  public firstDayMonth: any;
  public lastDayMonth: any;

  constructor(
    private userService: UserService,
    private dateHelperService: DateHelperService,
    private userTargetService: UserTargetService,
    private strengthWorkoutService: StrengthWorkoutService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    const startDate = this.currentWeek[0];
    const endDate = this.currentWeek[6];

    if (!this.userService.userData.getValue()) {
      this.userService.userData.subscribe((userData) => {
        if (userData) {
          this.userData = userData;
          this.currentWeekNumber = userData.current_week;
        }
      });
    } else {
      this.userData = this.userService.userData.getValue();
      this.currentWeekNumber = this.userData.current_week;
    }

    forkJoin({
      workouts: this.strengthWorkoutService.fetchBetween(startDate, endDate),
      allWorkouts: this.strengthWorkoutService.fetchAll(),
      targets: this.userTargetService.fetchAll(),
    }).subscribe(
      (responseData) => {
        this.isLoading = false;

        this.allTargets = responseData.targets;
        this.currentTarget = this.userTargetService.getLatestTarget(
          this.allTargets,
          'strength'
        );
        if (this.currentTarget) {
          this.strengthWorkoutService.weeklyTarget.threshold =
            +this.currentTarget.target;
        }

        this.strengthWorkouts = responseData.workouts;
        this.allWorkouts = responseData.allWorkouts;

        this.currentWeekData = this.strengthWorkoutService.generateWeekArray(
          this.currentWeek,
          this.strengthWorkouts
        );

        this.bicepCurlsWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'bicepCurl'
        );

        this.pullUpsWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'pullUps'
        );
        this.sitToStandWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'sitToStand'
        );
        this.stepUpsWeekReport = this.strengthWorkoutService.getWeekReport(
          responseData.allWorkouts,
          this.dateHelperService.getLastWeek(),
          'stepUps'
        );

        this.daysHit = this.strengthWorkoutService.getDaysHit(
          this.currentWeekData
        );
        this.monthsExercised = this.strengthWorkoutService.getMonths(
          this.allWorkouts
        );

        // get programme weeks?
        this.programmeWeeks = this.dateHelperService.getProgrammeWeeks(
          this.userData.current_week
        );

        this.weeksExercised = this.strengthWorkoutService.getWeeksExercised(
          this.allWorkouts,
          this.programmeWeeks
        );

        this.getMonthData();
      },
      (error) => {
        this.errors.push(error.message);
      }
    );
  }

  getTotalWorkoutDuration() {
    return '';
  }
  toggleFeedback() {
    this.showFeedback = !this.showFeedback;
  }

  onPreviousWeek() {
    if (this.currentWeekNumber > 1) {
      this.currentWeek = this.dateHelperService.getWeekFor(
        moment(this.currentWeek[0]).subtract(1, 'week')
      );
      this.currentWeekNumber--;
      this.currentWeekData = this.strengthWorkoutService.generateWeekArray(
        this.currentWeek,
        this.allWorkouts
      );
    }
  }

  onNextWeek() {
    if (this.currentWeekNumber < this.userData.current_week) {
      this.currentWeek = this.dateHelperService.getWeekFor(
        moment(this.currentWeek[0]).add(1, 'week')
      );
      this.currentWeekNumber++;
      this.currentWeekData = this.strengthWorkoutService.generateWeekArray(
        this.currentWeek,
        this.allWorkouts
      );
    }
  }

  getMonthData() {
    // get the current weeks in the month...
    let currentDate = moment(this.monthSelected);
    let currentMonth: any = [];
    let start = currentDate.clone().startOf('month').startOf('isoWeek');
    let end = currentDate.clone().endOf('month').endOf('isoWeek');

    this.firstDayMonth = currentDate
      .clone()
      .startOf('month')
      .format('YYYY-MM-DD');
    this.lastDayMonth = currentDate.clone().endOf('month').format('YYYY-MM-DD');

    while (start.isSameOrBefore(end)) {
      currentMonth.push(JSON.parse(JSON.stringify(start)));
      start.add(1, 'days');
    }

    this.monthData = this.strengthWorkoutService.generateLastMonthWeeksArray(
      currentMonth,
      this.allWorkouts
    );

    let last4WeeksDataForStats: any = [];

    this.allWorkouts.forEach((workout) => {
      if (
        moment(workout.workout_date) >= moment(currentMonth[0]) &&
        moment(workout.workout_date) <=
          moment(currentMonth[currentMonth.length - 1])
      ) {
        last4WeeksDataForStats.push(workout);
      }
    });

    this.stats = this.strengthWorkoutService.getStats(
      last4WeeksDataForStats
      // this.allWeeklyTargets
    );
    if (this.monthData[1].daysHit >= 5) {
      this.stats.targetHit++;
    }
    if (this.monthData[2].daysHit >= 5) {
      this.stats.targetHit++;
    }
    if (this.monthData[3].daysHit >= 5) {
      this.stats.targetHit++;
    }
    if (this.monthData[4].daysHit >= 5) {
      this.stats.targetHit++;
    }

    if (this.monthData[5] && this.monthData[5].daysHit >= 5) {
      this.stats.targetHit++;
    }

    if (this.monthData[6] && this.monthData[6].daysHit >= 5) {
      this.stats.targetHit++;
    }
  }

  onPrevMonth() {
    this.monthSelected = moment(this.monthSelected)
      .subtract(1, 'month')
      .toDate();
    this.getMonthData();
  }

  onNextMonth() {
    this.monthSelected = moment(this.monthSelected).add(1, 'month').toDate();
    this.getMonthData();
  }
}
