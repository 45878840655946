import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserService } from 'src/app/auth/service/user.service';
import { Symptoms } from 'src/app/models/symptoms.model';
import { NavbarService } from 'src/app/services/navbar.service';
import { UserSymptomsService } from 'src/app/services/user-symptoms.service';
import Swal from 'sweetalert2/src/sweetalert2.js';

@Component({
  selector: 'app-health-status-update',
  templateUrl: './health-status-update.component.html',
  styleUrls: ['./health-status-update.component.scss'],
})
export class HealthStatusUpdateComponent implements OnInit {
  @ViewChild('form', { static: false }) entryForm!: NgForm;
  symptomData: any = {
    cough: '',
    fatigue: '',
    breathlessness: '',
    sleep: '',
    anxiety: '',
    mood: '',
    memory: '',
    appetite: '',
  };
  public symptoms: Symptoms[] = [];
  isLoading = false;
  error = null;
  private errorSub!: Subscription;
  public userSymptomId!: number;
  public currentSymptom: any;
  public symptom!: Symptoms;

  public diagnosisMode: any;
  public userData: any;
  userSymptoms: any;
  symptomsBaseline: any;

  constructor(
    private userSymptomsService: UserSymptomsService,
    private datePipe: DatePipe,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private navbarService: NavbarService
  ) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.userService.userData.subscribe((userData) => {
      if (userData) {
        this.userData = userData;

        if (this.userData.primary_diagnosis) {
          this.diagnosisMode = this.userService.getDiagnosisMode(
            this.userData.primary_diagnosis
          );
        }
      }
    });

    // are we fetching an existing or latest?
    this.currentSymptom = this.route.snapshot.params.symptom;
    this.userSymptomId = this.route.snapshot.params.id;

    let symptomTitle = 'Symptom';
    this.userSymptomsService.symptomsArray.forEach((__symptom) => {
      if (__symptom.id == this.currentSymptom) {
        symptomTitle = __symptom.title;
      }
    });

    // get them all
    this.userSymptomsService.fetchAll().subscribe((symptoms) => {
      this.userSymptoms = symptoms;
      this.userSymptoms.forEach((symptoms: any) => {
        if (symptoms.baseline == 1 && !this.symptomsBaseline) {
          this.symptomsBaseline = symptoms;
        }
      });
    });

    this.navbarService.customTitle.next('Update ' + symptomTitle);

    if (this.userSymptomId) {
      this.userSymptomsService.fetch(this.userSymptomId).subscribe(
        (symptom) => {
          this.symptom = symptom;
          this.isLoading = false;
          if (symptom) {
            this.userSymptomsService.symptomsArray.forEach((symptomItem) => {
              this.symptomData[symptomItem.id] =
                this.userSymptomsService.getSymptomScore(
                  symptom,
                  symptomItem.id
                );
            });
          }
        },
        (error) => {
          this.isLoading = false;
          this.error = error.message;
        }
      );
    } else {
      this.userSymptomsService.fetchAll().subscribe(
        (symptoms) => {
          this.isLoading = false;
          this.symptoms = symptoms;

          if (symptoms[0]) {
            this.userSymptomsService.symptomsArray.forEach((symptomItem) => {
              this.symptomData[symptomItem.id] =
                this.userSymptomsService.getSymptomScore(
                  symptoms[0],
                  symptomItem.id
                );
            });
          } else {
            this.router.navigate(['/trackers', 'health-status', 'setup']);
          }
        },
        (error) => {
          this.isLoading = false;
          this.error = error.message;
        }
      );
    }
  }

  onSubmit() {
    if (this.userSymptomId) {
      this.updateSymptom();
    } else {
      this.addSymptom();
    }
  }

  private addSymptom() {
    this.userSymptomsService
      .create(
        this.entryForm.value.cough,
        this.entryForm.value.phlegm,
        this.entryForm.value.chest_tightness,
        this.entryForm.value.energy,
        this.entryForm.value.sob,
        this.entryForm.value.activity,
        this.entryForm.value.sleep,
        this.entryForm.value.confidence,
        this.entryForm.value.mood,
        this.entryForm.value.appetite,
        this.entryForm.value.ischaemic_pain,
        this.entryForm.value.ankle_swelling,
        this.entryForm.value.surgery_pain,
        this.entryForm.value.anxiety,
        this.entryForm.value.fatigue,
        this.entryForm.value.memory,
        0
      )
      .subscribe(
        (responseData) => {
          let message = '';
          let lastSymptom;
          if (this.userSymptomId) {
            lastSymptom = this.symptom;
          } else {
            lastSymptom = this.symptoms[0];
          }

          if (responseData.body) {
            this.router.navigate([
              '/trackers',
              'health-status',
              this.currentSymptom,
            ]);
          } else {
            this.router.navigate(['/trackers', 'health-status']);
          }
        },
        (error) => {
          this.error = error.message;
        }
      );
  }

  private updateSymptom() {
    this.userSymptomsService
      .update(
        this.userSymptomId,
        this.entryForm.value.cough,
        this.entryForm.value.phlegm,
        this.entryForm.value.chest_tightness,
        this.entryForm.value.energy,
        this.entryForm.value.sob,
        this.entryForm.value.activity,
        this.entryForm.value.sleep,
        this.entryForm.value.confidence,
        this.entryForm.value.mood,
        this.entryForm.value.appetite,
        this.entryForm.value.ischaemic_pain,
        this.entryForm.value.ankle_swelling,
        this.entryForm.value.surgery_pain,
        this.entryForm.value.anxiety,
        this.entryForm.value.fatigue,
        this.entryForm.value.memory,
        0
      )
      .subscribe(
        (responseData: any) => {
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Symptoms updated',
            showConfirmButton: false,
            timer: 2000,
            timerProgressBar: true,
            toast: true,
          });
          this.router.navigate([
            '/trackers',
            'health-status',
            this.currentSymptom,
          ]);
        },
        (error: any) => {
          this.error = error.message;
        }
      );
  }

  onDelete() {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to undo this",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it',
    }).then((result) => {
      if (result.value) {
        this.userSymptomsService.delete(this.userSymptomId).subscribe(
          (responseData) => {
            Swal.fire('Deleted', 'Your record has been deleted.', 'success');
            this.router.navigate(['/trackers', 'health-status']);
          },
          (error) => {
            this.error = error.message;
            Swal.fire('Deleted!', 'There was an error.', 'error');
          }
        );
      }
    });
  }
}
