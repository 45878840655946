<div class="d-flex justify-content-between strength-icons-day">
  <ng-container *ngFor="let exercise of exercises; let index = index">
    <ng-container *ngIf="exerciseData">
      <span [ngClass]="{ 'strength-icon-active': exerciseData['' + exercise.id].set1,  'strength-icon-inactive': !exerciseData['' + exercise.id].set1 }">
        <app-strength-icon [exerciseId]="exercise.id"></app-strength-icon>

        <!--span class="mb-1">
          ({{ exerciseData["" + exercise.id].weight }} {{ "KG" }})
  
          <app-difficulty-icon
            [difficulty]="+exerciseData[exercise.id].difficulty"
          ></app-difficulty-icon>
        </span-->
      </span>
    </ng-container>
  </ng-container>
</div>
