<div class="main-content-area bg-white pt-0">
  <app-loading-spinner *ngIf="isFetching" [inline]="1"></app-loading-spinner>

  <div class="card text-center card-light">
    <div class="text-center">
      <div *ngIf="symptoms[0]">
        <div class="health-status-score">
          <ng-container
            *ngFor="let symptomInf of userSymptomsService.symptomsArray"
          >
            <span
              class="icon-wrap sympton-icon-highlight text-secondary"
              *ngIf="currentSymptom == symptomInf.id"
            >
              <app-symptom-icon [symptomId]="symptomInf.id"></app-symptom-icon>
            </span>
          </ng-container>
          <h2 class="text-dark symptom-score">
            <span class="sr-only">Your most recent rating was</span>
            {{
              userSymptomsService.getSymptomScore(symptoms[0], currentSymptom)
            }}
          </h2>
        </div>
        <div class="last-update-text">
          Last updated : {{ symptoms[0].created | customDate : "ddd Do MMM" }}
        </div>
        <div class="mt-2 mb-3">
          <div class="progress progress-symptom">
            <div
              class="progress-bar"
              [class]="
                userSymptomsService.getSymptomScore(symptoms[0], currentSymptom)
                  | symptomBg : 5
              "
              role="progressbar"
              style="width:
              {{
                userSymptomsService.getSymptomScore(
                  symptoms[0],
                  currentSymptom
                ) == 0
                  ? '100'
                  : (userSymptomsService.getSymptomScore(
                      symptoms[0],
                      currentSymptom
                    ) /
                      5) *
                    100
              }}%"
              [attr.aria-valuenow]="
                userSymptomsService.getSymptomScore(
                  symptoms[0],
                  currentSymptom
                ) == 0
                  ? '100'
                  : (userSymptomsService.getSymptomScore(
                      symptoms[0],
                      currentSymptom
                    ) /
                      5) *
                    100
              "
              aria-valuemin="0"
              aria-valuemax="100"
              [attr.aria-label]=" 
              userSymptomsService.getSymptomScore(
                symptoms[0],
                currentSymptom
              ) == 0
                ? '100'
                : (userSymptomsService.getSymptomScore(
                    symptoms[0],
                    currentSymptom
                  ) /
                    5) *
                  100"
            ></div>
            <span class="line"></span><span class="line"></span
            ><span class="line"></span><span class="line"></span>
          </div>
        </div>
      </div>
    </div>
    <p *ngIf="symptomsBaseline" class="sympton-desc">
      Your baseline level for this tracker is set at
      <b>{{ symptomsBaseline[currentSymptom] }}</b>
    </p>
    <p
      *ngIf="symptomsBaseline && symptomsBaseline[currentSymptom] < 3"
      class="sympton-desc"
    >
      A high level for this tracker is <b>3</b> and above.
    </p>
    <p
      *ngIf="
        symptomsBaseline &&
        symptomsBaseline[currentSymptom] >= 3 &&
        symptomsBaseline[currentSymptom] != 5
      "
      class="sympton-desc"
    >
      A high level for this tracker is
      <b>{{ symptomsBaseline[currentSymptom] + 1 }}</b> and above.
    </p>
    <div *ngIf="symptoms[0]" class="mt-1">
      <!-- new 'chart' -->

      <div class="symptoms-chart">
        <div
          *ngFor="let symptom of symptoms.slice().reverse(); let index = index"
        >
          <ng-container *ngFor="let number of [5, 4, 3, 2, 1, 0]">
            <span
              class="circle-intensity m-1"
              *ngIf="
                userSymptomsService.getSymptomScore(symptom, currentSymptom) ==
                number
              "
              [class]="
                userSymptomsService.getSymptomScore(symptom, currentSymptom)
                  | symptomBg : 5
              "
              >{{
                userSymptomsService.getSymptomScore(symptom, currentSymptom)
              }}</span
            >
            <span
              class="circle-intensity m-1 bg-light"
              *ngIf="
                userSymptomsService.getSymptomScore(symptom, currentSymptom) !=
                number
              "
            >
            </span>
          </ng-container>
        </div>

        <ng-container
          *ngFor="let number of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]"
        >
          <div *ngIf="number > symptoms.length">
            <ng-container *ngFor="let number of [5, 4, 3, 2, 1, 0]">
              <span class="circle-intensity text-dark m-1 bg-light"> </span>
            </ng-container>
          </div>
        </ng-container>
      </div>
      <div class="symptoms-chart-arrow">
        <div class="symptoms-chart-line"></div>
        <div class="symptoms-chart-point"></div>
      </div>

      <div *ngIf="symptoms[1] != undefined">
        <app-health-status-feedback
          [currentScore]="
            userSymptomsService.getSymptomScore(symptoms[0], currentSymptom)
          "
          [previousScore]="
            userSymptomsService.getSymptomScore(symptoms[1], currentSymptom)
          "
          [symptom]="currentSymptom"
        ></app-health-status-feedback>
      </div>

      <button
        class="btn btn-primary d-none d-sm-inline-block mt-3"
        [routerLink]="['/trackers', 'health-status', 'update', currentSymptom]"
      >
        <span class="icon-wrap"
          ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
        ></span>
        Add an entry
      </button>
      <table class="table-workouts my-4">
        <tbody>
          <tr *ngFor="let symptom of symptoms; let index = index">
            <td>
              {{ symptom.created | customDate : "ddd Do MMM" }}
            </td>

            <td>
              <span
                class="circle-intensity"
                [class]="
                  userSymptomsService.getSymptomScore(symptom, currentSymptom)
                    | symptomBg : 5
                "
                >{{
                  userSymptomsService.getSymptomScore(symptom, currentSymptom)
                }}</span
              >
            </td>
            <td>
              <ng-container *ngIf="symptoms[index + 1]">
                <span
                  [ngClass]="{
                    'text-danger':
                      getSymptomDiff(
                        symptom,
                        symptoms[index + 1],
                        currentSymptom
                      ) > 0,
                    'text-success':
                      getSymptomDiff(
                        symptom,
                        symptoms[index + 1],
                        currentSymptom
                      ) <= 0
                  }"
                >
                  {{
                    getSymptomDiff(symptom, symptoms[index + 1], currentSymptom)
                  }}</span
                >
              </ng-container>
            </td>
            <td>
              <a
                [routerLink]="[
                  '/trackers',
                  'health-status',
                  'update',
                  this.currentSymptom,
                  symptom.user_symptom_id
                ]"
                class="text-secondary stretched-link"
              >
              <span class="sr-only">View Record</span>
                <span><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon></span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <!--ul class="tracker-list mt-4">
        <li *ngFor="let symptom of symptoms">
          <a
            [routerLink]="[
              '/trackers',
              'health-status',
              'view',
              this.currentSymptom,
              symptom.user_symptom_id
            ]"
          >
            <span class="tracker-icon">
              {{
                userSymptomsService.getSymptomScore(symptom, currentSymptom)
              }}
            </span>
            <span class="tracker-info">
              <span class="tracker-link">
                {{ symptom.created | date: "dd MMM yyyy" }}
                <span class="icon-wrap float-end icon-more"
                ><fa-icon [icon]="['fal', 'arrow-right']"></fa-icon
              ></span></span>
              <span class="progress progress-symptom">
                <span
                  class="progress-bar"
                  [class]="
                    userSymptomsService.getSymptomScore(
                        symptom,
                      currentSymptom
                    ) | symptomBg: 5
                  "
                  role="progressbar"
                  style="width: {{
                    (userSymptomsService.getSymptomScore(
                        symptom,
                      currentSymptom
                    ) /
                      5) *
                      100
                  }}%"
                  [attr.aria-valuenow]="
                    (userSymptomsService.getSymptomScore(
                        symptom,
                      currentSymptom
                    ) /
                      5) *
                    100
                  "
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></span>
                <span class="line"></span><span class="line"></span
                ><span class="line"></span><span class="line"></span>
              </span>
            </span>
          </a>
        </li>
      </ul-->
    </div>
  </div>
  <button
    class="btn btn-primary btn-add d-block d-sm-none sticky-button"
    [routerLink]="['/trackers', 'health-status', 'update', currentSymptom]"
  >
    <span class="icon-wrap"
      ><fa-icon [icon]="['fal', 'circle-plus']"></fa-icon
    ></span>
    Add an entry
  </button>
</div>
